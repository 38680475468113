import { CMSTransformerDataType } from '../../types/common';
import COMPONENT_KEY, { ProgressiveProfileWrapperProps } from './types/propsTypes';

const transformProfileWrapper = (data: CMSTransformerDataType): ProgressiveProfileWrapperProps => {
  const progressiveProfileImageData = data?.profile_stepper?.[0]?.image?.images;

  return {
    componentType: COMPONENT_KEY.PROFILE_STEPPER,
    progressiveProfileImageData,
  };
};

export default transformProfileWrapper;
