import { ConfigService } from '@nextgen-web-framework/core';

import { SanitizeComponentsConfig } from '@commerce-webcomponents/ui';

const configs = ConfigService.getConfigs();
//Applicable only where ModularBlockComponent is used
export const sanitizationConfig: SanitizeComponentsConfig = {
  rich_text_component: { sanitize: true, sanitizationConfig: { ADD_TAGS: ['iframe'] } },
  faqs: { sanitize: true, sanitizationConfig: { ADD_TAGS: ['iframe'] } },
  faq_groups: { sanitize: true, sanitizationConfig: { ADD_TAGS: ['iframe'] } },
  banner_rich_text_component: { sanitize: true, sanitizationConfig: { ADD_TAGS: ['iframe'] } },
};
