import { useEffect, useState } from 'react';
import { ProgressiveProfileNextStepEnum } from '@commerce-profile-webcomponents/sdk';
import { ProgressiveProfile } from '@commerce-profile-webcomponents/ui';
import { useSession, SkeletonFrame } from '@nextgen-web-framework/all';
import { StorageService } from '@nextgen-web-framework/core';
import styles from './ProgressiveProfileWrapper.module.scss';
import { ProgressiveProfileWrapperProps } from './types/propsTypes';

const ProgressiveProfileWrapper = (props: ProgressiveProfileWrapperProps) => {
  const cookie = StorageService.getCookieStorage();
  const session = useSession();
  const [profileCompleted, setProfileCompleted] = useState(true);

  useEffect(() => {
    if (window) {
      const status = cookie.retrieve('tasks-completed');
      setProfileCompleted(status === 'true');
    }
  }, [session, cookie]);

  return (
    <div className={styles['homepage-profile-stepper']} data-testid="profile-stepper-homepage-id">
      {(session.isABO() || session.isMember()) && !profileCompleted ? (
        <ProgressiveProfile
          subtitleText={'profile.progressiveStepper.subheadingHomepage'}
          showHeading={true}
          nextStepType={ProgressiveProfileNextStepEnum.BUTTON}
          homepageImage={props?.progressiveProfileImageData}
        />
      ) : (
        <div className={styles['skeleton-loader-container']}>
          <SkeletonFrame isRoundCorner={false} />
        </div>
      )}
    </div>
  );
};

export default ProgressiveProfileWrapper;
