import { ImageCMSType } from '@commerce-profile-webcomponents/sdk';
export interface ProgressiveProfileWrapperProps {
  componentType: string;
  progressiveProfileImageData?: ImageCMSType[];
}

const COMPONENT_KEY = {
  PROFILE_STEPPER: 'profile_stepper',
};

export default COMPONENT_KEY;
