import aboutAmwayTemplateStyles from '../styles/templates/aboutAmwayTemplate.module.scss';
import mainLandingTemplateStyles from '../styles/templates/mainLandingTemplate.module.scss';
import contentDetailTemplateStyles from '../styles/templates/contentDetailTemplate.module.scss';
import productDetailTemplateStyles from '../styles/templates/productDetailTemplate.module.scss';

export enum TemplateType {
  MAIN_LANDING = 'main_landing_template',
  FLEX = 'flex_template',
  A_POINT_CATALOG_LANDING = 'a_point_catalog_template',
  ABOUT_AMWAY = 'about_amway_template',
  RESOURCE_DETAIL = 'resource_detail_template',
  ARTICLE_DETAIL = 'article_detail_template',
  PROMOTION_DETAIL = 'promotion_detail_template',
}

const mainLandingStyles = {
  rich_text_component: mainLandingTemplateStyles['centered-container'],
  quick_links_design_two: mainLandingTemplateStyles['centered-container'],
  featured_display_cards_carousal: mainLandingTemplateStyles['standard-carousel-container'],
  product_carousal_banner: mainLandingTemplateStyles['full-width-container'],
  featured_cards: mainLandingTemplateStyles['standard-carousel-container'],
  featured_articles_carousal: mainLandingTemplateStyles['standard-carousel-container'],
  product_carousel: mainLandingTemplateStyles['full-width-container'],
  a_point_product_carousel: mainLandingTemplateStyles['standard-carousel-container'],
  resource_carousel: mainLandingTemplateStyles['standard-carousel-container'],
  promotion_carousel: mainLandingTemplateStyles['standard-carousel-container'],
  article_carousel: mainLandingTemplateStyles['standard-carousel-container'],
  meet_our_scientists: mainLandingTemplateStyles['full-width-container'],
  amway_promises: mainLandingTemplateStyles['centered-container'],
  highlights_c: mainLandingTemplateStyles['centered-container'],
  quick_links_design_one: mainLandingTemplateStyles['full-width-container'],
  amway_highlights: mainLandingTemplateStyles['centered-container'],
  amway_business_links: mainLandingTemplateStyles['full-width-container'],
  steps_to_business_owner: mainLandingTemplateStyles['full-width-container'],
  faqs: mainLandingTemplateStyles['centered-container'],
  faq_groups: mainLandingTemplateStyles['centered-container'],
  download_links: mainLandingTemplateStyles['centered-container'],
  video_section: mainLandingTemplateStyles['centered-container'],
  image_with_optional_cta: mainLandingTemplateStyles['centered-container'],
  text_only: mainLandingTemplateStyles['centered-container'],
  audio_player: mainLandingTemplateStyles['centered-container'],
  image_and_text: mainLandingTemplateStyles['centered-container'],
  grid_view_image_gallery: mainLandingTemplateStyles['centered-container'],
  accordion_component: mainLandingTemplateStyles['centered-container'],
  hero_component: mainLandingTemplateStyles['full-width-container'],
};

const contentDetailStyles = {
  rich_text_component: contentDetailTemplateStyles['column-based-component-container'],
  quick_links_design_two: contentDetailTemplateStyles['column-based-component-container'],
  featured_display_cards_carousal: contentDetailTemplateStyles['carousel-component-container'],
  product_carousal_banner: contentDetailTemplateStyles['full-width-container'],
  featured_cards: contentDetailTemplateStyles['carousel-component-container'],
  featured_articles_carousal: contentDetailTemplateStyles['carousel-component-container'],
  product_carousel: contentDetailTemplateStyles['full-width-container'],
  a_point_product_carousel: contentDetailTemplateStyles['column-based-component-container'],
  resource_carousel: contentDetailTemplateStyles['carousel-component-container'],
  promotion_carousel: contentDetailTemplateStyles['carousel-component-container'],
  article_carousel: contentDetailTemplateStyles['carousel-component-container'],
  meet_our_scientists: contentDetailTemplateStyles['full-width-container'],
  amway_promises: contentDetailTemplateStyles['column-based-component-container'],
  highlights_c: contentDetailTemplateStyles['column-based-component-container'],
  quick_links_design_one: contentDetailTemplateStyles['full-width-container'],
  amway_highlights: contentDetailTemplateStyles['column-based-component-container'],
  amway_business_links: contentDetailTemplateStyles['full-width-container'],
  steps_to_business_owner: contentDetailTemplateStyles['full-width-container'],
  faqs: contentDetailTemplateStyles['column-based-component-container'],
  faq_groups: contentDetailTemplateStyles['column-based-component-container'],
  download_links: contentDetailTemplateStyles['column-based-component-container'],
  video_section: contentDetailTemplateStyles['column-based-component-container'],
  image_with_optional_cta: contentDetailTemplateStyles['column-based-component-container'],
  text_only: contentDetailTemplateStyles['column-based-component-container'],
  audio_player: contentDetailTemplateStyles['column-based-component-container'],
  image_and_text: contentDetailTemplateStyles['column-based-component-container'],
  accordion_component: contentDetailTemplateStyles['column-based-component-container'],
  grid_view_image_gallery: contentDetailTemplateStyles['column-based-component-container'],
  hero_component: contentDetailTemplateStyles['full-width-container'],
};

const productDetailStyles = {
  rich_text_component: productDetailTemplateStyles['column-based-component-container'],
  quick_links_design_two: productDetailTemplateStyles['column-based-component-container'],
  featured_display_cards_carousal: productDetailTemplateStyles['carousel-component-container'],
  product_carousal_banner: productDetailTemplateStyles['full-width-container'],
  featured_cards: productDetailTemplateStyles['carousel-component-container'],
  featured_articles_carousal: productDetailTemplateStyles['carousel-component-container'],
  product_carousel: productDetailTemplateStyles['full-width-container'],
  a_point_product_carousel: productDetailTemplateStyles['column-based-component-container'],
  resource_carousel: productDetailTemplateStyles['carousel-component-container'],
  promotion_carousel: productDetailTemplateStyles['carousel-component-container'],
  article_carousel: productDetailTemplateStyles['carousel-component-container'],
  meet_our_scientists: productDetailTemplateStyles['full-width-container'],
  amway_promises: productDetailTemplateStyles['column-based-component-container'],
  highlights_c: productDetailTemplateStyles['column-based-component-container'],
  quick_links_design_one: productDetailTemplateStyles['full-width-container'],
  amway_highlights: productDetailTemplateStyles['column-based-component-container'],
  amway_business_links: productDetailTemplateStyles['full-width-container'],
  steps_to_business_owner: productDetailTemplateStyles['full-width-container'],
  faqs: productDetailTemplateStyles['column-based-component-container'],
  faq_groups: productDetailTemplateStyles['column-based-component-container'],
  download_links: productDetailTemplateStyles['column-based-component-container'],
  video_section: productDetailTemplateStyles['column-based-component-container'],
  image_with_optional_cta: productDetailTemplateStyles['column-based-component-container'],
  text_only: productDetailTemplateStyles['column-based-component-container'],
  audio_player: productDetailTemplateStyles['column-based-component-container'],
  image_and_text: productDetailTemplateStyles['column-based-component-container'],
  accordion_component: productDetailTemplateStyles['column-based-component-container'],
  grid_view_image_gallery: productDetailTemplateStyles['column-based-component-container'],
  hero_component: productDetailTemplateStyles['full-width-container'],
};

const componentStylesConfig: Record<TemplateType, Record<string, string>> = {
  [TemplateType.MAIN_LANDING]: {
    ...mainLandingStyles,
  },
  [TemplateType.FLEX]: {
    ...mainLandingStyles,
  },
  [TemplateType.A_POINT_CATALOG_LANDING]: {
    ...mainLandingStyles,
  },
  [TemplateType.ABOUT_AMWAY]: {
    rich_text_component: aboutAmwayTemplateStyles['centered-container'],
    quick_links_design_two: aboutAmwayTemplateStyles['centered-container'],
    featured_display_cards_carousal: aboutAmwayTemplateStyles['full-width-container'],
    product_carousal_banner: aboutAmwayTemplateStyles['full-width-container'],
    featured_cards: aboutAmwayTemplateStyles['standard-carousel-container'],
    featured_articles_carousal: aboutAmwayTemplateStyles['standard-carousel-container'],
    product_carousel: aboutAmwayTemplateStyles['full-width-container'],
    a_point_product_carousel: aboutAmwayTemplateStyles['standard-carousel-container'],
    resource_carousel: aboutAmwayTemplateStyles['standard-carousel-container'],
    promotion_carousel: aboutAmwayTemplateStyles['standard-carousel-container'],
    article_carousel: aboutAmwayTemplateStyles['standard-carousel-container'],
    meet_our_scientists: aboutAmwayTemplateStyles['full-width-container'],
    amway_promises: aboutAmwayTemplateStyles['centered-container'],
    highlights_c: aboutAmwayTemplateStyles['full-width-container'],
    quick_links_design_one: aboutAmwayTemplateStyles['full-width-container'],
    amway_highlights: aboutAmwayTemplateStyles['centered-container'],
    amway_business_links: aboutAmwayTemplateStyles['full-width-container'],
    steps_to_business_owner: aboutAmwayTemplateStyles['full-width-container'],
    faqs: aboutAmwayTemplateStyles['centered-container'],
    faq_groups: aboutAmwayTemplateStyles['centered-container'],
    download_links: aboutAmwayTemplateStyles['centered-container'],
    video_section: aboutAmwayTemplateStyles['centered-container'],
    image_with_optional_cta: aboutAmwayTemplateStyles['centered-container'],
    text_only: aboutAmwayTemplateStyles['centered-container'],
    accordion_component: aboutAmwayTemplateStyles['centered-container'],
    audio_player: aboutAmwayTemplateStyles['centered-container'],
    image_and_text: aboutAmwayTemplateStyles['centered-container'],
    grid_view_image_gallery: aboutAmwayTemplateStyles['centered-container'],
    hero_component: aboutAmwayTemplateStyles['hero-component-container'],
  },
  [TemplateType.RESOURCE_DETAIL]: {
    ...productDetailStyles,
  },
  [TemplateType.ARTICLE_DETAIL]: {
    ...contentDetailStyles,
  },
  [TemplateType.PROMOTION_DETAIL]: {
    ...productDetailStyles,
  },
};

export const getComponentStylesConfig = (template: TemplateType) => {
  return componentStylesConfig[template] || {};
};
